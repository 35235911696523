
/* eslint no-invalid-this: 0 */
/* eslint vars-on-top: 0 */
/* eslint no-undef: 0 */
/* eslint no-prototype-builtins: 0 */
import { AutomatitCarousel } from './automatit_carousel.m';
import { contactSubmit } from './contact.js';


if (document.getElementById('contact_form')) {
	contactSubmit();
}

if (document.getElementById('slide1')) {
	const options = {
		element: document.getElementById('slide1'),
		slideSelector: '.item',
	};
	AutomatitCarousel(options);
}

if (document.getElementById('slide2')) {
	const options = {
		element: document.getElementById('slide2'),
		slideSelector: '.item',
	};
	AutomatitCarousel(options);
}

(function(window, document) {
	// helper functions
	var trim = function(str) {
		return str.trim ? str.trim() : str.replace(/^\s+|\s+$/g,'');
	};

	var hasClass = function(el, cn) {
		return (' ' + el.className + ' ').indexOf(' ' + cn + ' ') !== -1;
	};

	var addClass = function(el, cn) {
		if (!hasClass(el, cn)) {
			el.className = (el.className === '') ? cn : el.className + ' ' + cn;
		}
	};

	var removeClass = function(el, cn) {
		el.className = trim((' ' + el.className + ' ').replace(' ' + cn + ' ', ' '));
	};

	var hasParent = function(el, id) {
		if (el) {
			do {
				if (el.id === id) {
					return true;
				}
				if (el.nodeType === 9) {
					break;
				}
			}
			while((el = el.parentNode));
		}
		return false;
	};

	// normalize vendor prefixes
	var doc = document.documentElement;
	//var transform_prop = window.Modernizr.prefixed('transform'),
	var	transition_prop = window.Modernizr.prefixed('transition'),
		transition_end = (function() {
			var props = {
				'WebkitTransition': 'webkitTransitionEnd',
				'MozTransition': 'transitionend',
				'OTransition': 'oTransitionEnd otransitionend',
				'msTransition': 'MSTransitionEnd',
				'transition': 'transitionend'
			};
			return props.hasOwnProperty(transition_prop) ? props[transition_prop] : false;
		})();

	window.App = (function() {

		var _init = false, app = {};
		var inner = document.getElementById('inner-wrap'),

			nav_open = false,
			nav_class = 'js-nav';


		app.init = function() {
			if (_init) {
				return;
			}
			_init = true;

			var closeNavEnd = function(e) {
				if (e && e.target === inner) {
					document.removeEventListener(transition_end, closeNavEnd, false);
				}
				nav_open = false;
			};

			app.closeNav = function() {
				if (nav_open) {
					// close navigation after transition or immediately
					var duration = (transition_end && transition_prop) ? parseFloat(window.getComputedStyle(inner, '')[transition_prop + 'Duration']) : 0;
					if (duration > 0) {
						document.addEventListener(transition_end, closeNavEnd, false);
					} else {
						closeNavEnd(null);
					}
				}
				removeClass(doc, nav_class);
			};

			app.openNav = function() {
				if (nav_open) {
					return;
				}
				addClass(doc, nav_class);
				nav_open = true;
			};

			app.toggleNav = function(e) {
				if (nav_open && hasClass(doc, nav_class)) {
					app.closeNav();
				} else {
					app.openNav();
				}
				if (e) {
					e.preventDefault();
				}
			};

			// open nav with main "nav" button
			document.getElementById('nav-open-btn').addEventListener('click', app.toggleNav, false);

			// close nav with main "close" button
			document.getElementById('nav-close-btn').addEventListener('click', app.toggleNav, false);

			// close nav by touching the partial off-screen content
			document.addEventListener('click', function(e) {
				if (nav_open && !hasParent(e.target, 'nav')) {
					e.preventDefault();
					app.closeNav();
				}
			},
			true);

			addClass(doc, 'js-ready');

		};
		app.doSiteSearch = function () {
			window.location = '/SearchResults.aspx?searchterm=' + encodeURIComponent($("#txtSearch").val());
		};
		return app;

	})();

	if (window.addEventListener) {
		window.addEventListener('DOMContentLoaded', window.App.init, false);
	}

	if ($(window).width() < 769) {
		// SLiding codes
		$("#toggle > li > div").click(function () {
			if ($(this).next().is(':visible') === false) {
				$('#toggle ul').slideUp();
			}

			var $currIcon=$(this).find("span.the-btn");

			$("span.the-btn").not($currIcon).addClass('fa-plus').removeClass('fa-minus');

			$currIcon.toggleClass('fa-minus fa-plus');

			$(this).next().slideToggle();

			$("#toggle > li > div").removeClass("active");
			$(this).addClass('active');

		});

	}

})(window, window.document);

if ($(window).width() < 768) {
	$(window).scroll(function(){
		if ($(this).scrollTop() > 70) {
			$('#map_phone').slideDown('fast');
		} else {
			$('#map_phone').slideUp('fast');
		}
	}); 
}